import { useEffect, useState } from "react";
import api from "../../services/api";
import "./style.css"

function WhatsApp() {
    /*const [whatsapp, setWhatsapp] = useState();
    useEffect(() => {
        api.get('/v1/empresa/1')
            .then(response => {
                setWhatsapp(response.data);
                console.log(response.data);
            })
            .catch(err => {

            })
    }, []);*/
    return <div class="">
        <a className="btn btn-light btn-lg w-100 btn-whats" href="https://api.whatsapp.com/send/?phone=558597230019" target="blank">
            <i class="fab fa-whatsapp fa-font"></i> &nbsp;&nbsp; Fale conosco
        </a>

    </div>
}

export default WhatsApp;  