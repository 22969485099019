import Logo from '../../../src/Imagens/ISOLOGO1.png';
import "./style.css"
import { useNavigate } from "react-router-dom";

function Navbar() {
    const navigate = useNavigate();
    function Logout() {
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('sessionNomuser');
        localStorage.removeItem('sessionPlano');
        navigate('/');
    }

    return <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light ps-3 pe-3">
        <div className="container-fluid">
            <a className="navbar-brand" to="/"><img className="mt-1 logonav" src={Logo} /></a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">

                <div className="ms-auto me-auto mt-1">
                    {
                        /*<div className="input-group">
                            <input type="search"  className="form-control" placeholder="Procurar um restaurante..." aria-label="Search" />
                            <button onClick="{Buscar}" className="btn btn-danger" type="button" id="button-addon2"><i className="fas fa-search"></i> Buscar</button>
                        </div>*/
                    }
                </div>

                <div className="mt-1">
                    <a to="/trocar-endereco" className="btn btn-outline-dark me-3">
                        <i className="fas fa-map-marker-alt"></i> Blu: Fortaleza-ce
                    </a>
                    {
                        //<button className="btn btn-outline-primary me-3"><i className="fas fa-sign-in-alt"></i> Acessar</button>
                    }

                    <div className="btn-group" >
                        <button type="button" className="btn btn-outline-dark me-3 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" disabled>
                            <i className="fas fa-user "> &nbsp; </i>{localStorage.getItem('sessionNomuser')}
                        </button>
                        <ul className="dropdown-menu">
                            <li><a to="/perfil" className="dropdown-item" >Perfil</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a href="#"  className="dropdown-item">Sair</a></li>
                        </ul>
                    </div>

                    <a to="/trocar-endereco" onClick={Logout} className="btn btn-outline-dark me-3">
                        <i class="fas fa-sign-out-alt"></i> Sair
                    </a>

                    {
                        //<button  className="btn btn-outline-primary me-3"><i className="fas fa-shopping-bag"></i> Sacola </button>
                    }
                </div>

            </div>
        </div>
    </nav>
}

export default Navbar;