import Boletos from "../../components/boletos"
import Navbar from "../../components/navbar"
import Footer from "../../components/footer";
import BoletoModal from "../../components/modal";
import { useState } from "react";
import '../../styles/global.css';
import api from "../../services/api";

function Home() {
    const [isopenModalBoleto,setIsopenModalBoleto] = useState(false);
    const [DadosBoleto,setDadosBoleto] = useState(false);
    function openModalBoleto(id){
        if (id>0){
            api.get('/v1/boleto/' + localStorage.getItem('sessionId_usuario')+'/id_boleto='+id+'/'+localStorage.getItem('sessionIdEmpresa'))
            .then(response => {
                setDadosBoleto(response.data[0]);
                setIsopenModalBoleto(true);
            })
            .catch(err => {
                setDadosBoleto([]);
                setIsopenModalBoleto(true);
            })
        } else {

        }    
    }
    function closeModalBoleto(){
        setIsopenModalBoleto(false);
    }
    return <>
        <div className="container  py-4">
            <Navbar />
            <BoletoModal isOpen={isopenModalBoleto}
                         onRequestClose={closeModalBoleto} 
                         dados_boleto={DadosBoleto}/>
            <div className="row justify-content-center text-center mt-page">
            <div className="container text-start mb-3">
                <p className="fs-2 fw-normal"> Acompanhe seus boletos</p>
            </div>
             <Boletos onclickBoleto={openModalBoleto}/>   
            </div>
            
        </div>
        <Footer />
    </>
}

export default Home;