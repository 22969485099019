import "./style.css";
import { useState } from "react";
import Fundo from '../../assents/mulhercomcopo_1.png';
import Logo from '../../../src/Imagens/ISOLOGO1.png';
import api from '../../services/api.js';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import WhatsApp from "../../components/whatsapp";
import {mask} from "../../components/mascaras";


function Login() {
   const navigate = useNavigate();
   const [usuario, setUsuario] = useState('');
   const [senha, setSenha] = useState('');
   const [sucesso, setSucesso] = useState('');
   const [loading, setLoading] = useState(false);
   function handleChangeMask(event) {
      debugger;
      const { value } = event.target

      setUsuario(mask(value))
   }
   function ProcessaLogin(e) {
      debugger;
      e.preventDefault();
      if (usuario === '' || senha === '') {
         setSucesso('N');
         setLoading(false);
      } else {
         setSucesso('');
         setLoading(true);

         api.post('/v1/usuarios/login', {
            "usuario": usuario.replace(/[^0-9]/g,''),
            "senha": senha
         }).then(response => {
            debugger;
            localStorage.setItem('sessionToken', response.data.token);
            localStorage.setItem('sessionId_usuario', response.data.ID_USUARIO);
            localStorage.setItem('sessionNomuser', response.data.NOME);
            localStorage.setItem('sessionPlano', response.data.PLANO);
            localStorage.setItem('sessionDataAcesso', moment());
            localStorage.setItem('sessionIdEmpresa', response.data.ID_EMPRESA);
            localStorage.setItem('sessionInfoUser', usuario.replace(/[^0-9]/g,''));
            console.log(response);
            setSucesso('S');
            navigate('/home');
         }).catch(err => {
            debugger;
            console.log(err)
            setSucesso('N');
            setLoading(false);
         })
      }
   }

   return <div className="row">
      <div className="col-sm-7 d-flex justify-content-center align-items-center text-center">
         <form className="form-login mt-5">
            <img className="mt-4 logologin" src={Logo} />
            <br />
            <br />
            <h3 className="mb-4">Bem-vindo(a) à Blu</h3>
            <h6 className="mb-3">Acesse sua Conta</h6>

            <div className="form-floating">
               <input type="text" onChange={handleChangeMask/*(e) => setUsuario(e.target.value)*/} className="form-control" id="floatingInput" placeholder="Cpf/Cnpj" value={usuario}></input>
               <label for="floatingInput">Cpf/Cnpj</label>
            </div>
            <div className="form-floating">
               <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatingInput" placeholder="Senha"></input>
               <label for="floatingInput">Senha</label>
            </div>
            <button onClick={ProcessaLogin} className="w-100 btn btn-lg btn-primary" disabled={loading}>
               {loading ? <div>
                  <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                  <span className="ms-2">Enviando...</span>
               </div> : <span className="ms-2">Acessar</span>
               }
            </button>
            {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">Credenciais de acesso inválida</div> : null}

            <br />
            <br />
            <label class="labinfor">Sua senha encontra-se no campo MINHA BLU no seu boleto, caso tenha alguma dúvida entrar em contato no 4020-9500 ou atráves do WhatsApp</label>
            <WhatsApp />
         </form>
      </div>
      <div className="col-sm-5 px-0 d-none d-sm-block">
         <img className="background-login" src={Fundo} alt="Sistema Blu" />
      </div>
   </div>
}

export default Login;