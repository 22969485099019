import React from 'react';
import ReactDOM from 'react-dom';
import '../src/styles/global.css';
import Rotas from './rotas.js';
import { BrowserRouter } from "react-router-dom";


window.onbeforeunload = function () {
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('sessionId');
  localStorage.removeItem('sessionNomuser');
  localStorage.removeItem('sessionPlano');
};

ReactDOM.render(<BrowserRouter><Rotas /></BrowserRouter>,
  document.getElementById('root')
);

