import {  Routes, Route, } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import PrivateRoute from './components/private-route';

function Rotas() {
    return (
        <Routes>
            <Route index element={<Login />} />
            <Route  path="home" element={<PrivateRoute><Home /></PrivateRoute>} />  
        </Routes>
    )
}

export default Rotas;