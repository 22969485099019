import Moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../services/api";
import "./style.css";

function Boletos(props) {
    const [boletos, setBoletos] = useState([]);
    const navigate = useNavigate();
    ///api.get('/v1/boleto/ativabig');
    useEffect(() => {
        api.get('/v1/boletos/' + localStorage.getItem('sessionId_usuario')+'/'+localStorage.getItem('sessionIdEmpresa'))
            .then(response => {
                setBoletos(response.data);
                console.log(response.data);
            })
            .catch(err => {
                navigate('/home');
            })
    }, []);
   

    return <div className="card">
        
        <h5 className="card-header card-text-left">Plano atual:&nbsp;{localStorage.getItem('sessionPlano')}</h5>
      
        <div className="card-body">
            <div className="container-fluid">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>VENCIMENTO</th>
                            <th>VALOR TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            boletos.map(boleto => {
                                return <tr>
                                    <th disabled={boleto.LINHADIGITAVEL === null ?true:false}>
                                        <a disabled={boleto.STATUS === "PAGO" ?true:false} className="a-boleto link1" onClick={boleto.LINHADIGITAVEL.length === 0 ?(e) => alert('O boleto não está disponível'):(e) => props.onclickBoleto(boleto.ID_BOLETO)}  target="_blank" >
                                            <i className="fas fa-barcode" aria-hidden="true"></i><br />
                                            <strong>GERAR BOLETO</strong>
                                        </a>
                                    </th>
                                    <th>
                                        {boleto.DATAVENC1}<br />
                                        <span className={boleto.STATUS === 'Á VENCER' ? "dayemdia" : boleto.STATUS === 'VENCIDO' ? "dayvencido" : "daypago"}>{boleto.STATUS}</span>
                                    </th>
                                    <th>
                                         { boleto.VALOR.toLocaleString( 'pt-br',{style: 'currency', currency: 'BRL'})}
                                    </th>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>


}

export default Boletos;