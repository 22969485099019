import Modal from 'react-modal/lib/components/Modal';
import closeIcon from '../../assents/close.png'
 
function BoletoModal(props) { 
    var urlboleto ='';
    debugger;
   /* if (props.dados_boleto.PESSOA === "FISICA"){
        urlboleto = props.dados_boleto.CPF
    }else{ urlboleto = props.dados_boleto.CNPJ}; */
    if (props.dados_boleto.ID_EMPRESA===1){
        urlboleto = 'http://minhablu.com.br/doc/blu/'
    } else{  urlboleto = 'http://minhablu.com.br/doc/blu'+props.dados_boleto.ID_EMPRESA+'/'}
    urlboleto = urlboleto+localStorage.getItem('sessionInfoUser');
    urlboleto = urlboleto+'_'+props.dados_boleto.ANO+'-'+ (props.dados_boleto.MES<10 ? '0'+props.dados_boleto.MES:props.dados_boleto.MES)+'.pdf'
  
    return <Modal 
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        overlayClassName={'react-modal-overlay'}
        className={'react-modal-content'}>
        <button type="button" onClick={props.onRequestClose} className="react-modal-close">
            <img src={closeIcon} alt="Fechar" />
        </button>
        
        <div className='conteiner-fuid h-100 boleto-modal'>
            <div className='row detalhes-boleto'>
                <div className='col-12 mt-0 text-center'>
                    <p className="fs-2 fw-normal"> Boleto</p>
                </div>
                <div className='col-12 mt-1 text-start'>
                    <div className='row'>
                        <div className='col-6'>
                            <p className="fs-5 fw-bold mb-0"> VALOR DO BOLETO</p>
                            <p className="fs-6 mt-0"> R$ {props.dados_boleto.VALOR}</p>
                        </div>
                        <div className='col-6'>
                            <p className="fs-5 fw-bold mb-0"> VENCIMENTO</p>
                            <p className="fs-6 mt-0"> {props.dados_boleto.DATAVENC1}</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 mt-1 text-start'>
                    <p className="fs-5 fw-bold mb-0">CÓDIGO PARA PAGAMENTO</p>
                    <p className="fs-6 mt-0"> {props.dados_boleto.LINHADIGITAVEL}</p>
                </div>
                <div className='col-12 mt-3 text-start fs-6'>
                    <button type="button" className="btn btn-light " onClick={(e)=>{
                        debugger;
                        let linha=props.dados_boleto.LINHADIGITAVEL;
                        //linha.select();
                        //linha.setSelectinoRange(0,99999); 
                        document.Command('copy');
                        alert(linha);
                    }}><i className="fas fa-barcode" aria-hidden="true"></i> CÓPIAR CODIGO </button>
                </div>
                <div className='col-12 mt-3 text-center'>
                    <a href={/*</div>props.dados_boleto.URLBOLETO+*/urlboleto} className="btn btn-secondary" target="_blank">VISUALIZAR</a>
                </div>
            </div>
        </div>
    </Modal>
}

export default BoletoModal;